import React, {useEffect, useRef, useState} from "react";
import PropTypes                            from 'prop-types';
import VXButton                             from '../SimpleElements/VXButton/VXButton';
import {COLOR_TYPE_GOLD}                    from '../SimpleElements/VXButton/ButtonHelper';
import {VXButtonLineConfig}                 from '../SimpleElements/VXButton/VXButtonConfig';
import STYLES                               from '../SimpleElements/VXButton/EnumVXButton';
import Translations                         from '../../utils/Translations';
import Flux                                 from "../../flux/Flux.js";
import UrlBuilder                           from "../../utils/UrlBuilder.js";
import '../../../../src/VX/Asset/Less/VX/07-components/base/exitintent-box.less';

function useOutsideClick(ref, callback) {
	useEffect(() => {
		function handleClickOutside(event) {
			if (ref.current && !ref.current.contains(event.target)) {
				document.removeEventListener('click', handleClickOutside);
				return callback(event);
			} else {
				event.stopPropagation();
			}
		}

		document.addEventListener('click', handleClickOutside);
		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, [ref, callback]);
}

const t = (key) => Translations.get(`exit-intent.vip-${key}`);

const VipLogout = ({isAbsolute, onLogoutClick, incentive}) => {
	const onCloseIncentive = () => {
		Flux.Conversion.removeIncentiveCloseListener(onCloseIncentive);
		window.location.href = UrlBuilder.buildVIPClubUrl();
	};

	const onAccept = () => {
		Flux.Conversion.blockIncentive(incentive, {accept: true});
		Flux.Conversion.closeIncentive(incentive, {accept: true});

		Flux.Conversion.addIncentiveCloseListener(onCloseIncentive);
	};

	const onDecline = () => {
		Flux.Conversion.closeIncentive(incentive, {accept: false});
		onLogoutClick();
	};

	const modalRef            = useRef(null);
	const [accept, setAccept] = useState();
	const [show, setShow]     = useState(!Flux.Conversion.isIncentiveBlocked(incentive));

	useOutsideClick(modalRef, () => {
		setShow(false);
		Flux.Conversion.closeIncentive(incentive, {accept: false});
	});

	useEffect(() => {
		if (typeof accept !== 'undefined') {
			if (accept) {
				onAccept();
			} else {
				onDecline();
			}
		}
	}, [accept]);

	//  on mount
	useEffect(() => {
		Flux.Conversion.blockIncentive(incentive, {accept: false});

		return () => {
			if (!accept) {
				Flux.Conversion.closeIncentive(incentive, {accept: false});
			}
		};
	}, []);

	if (!show) {
		return null;
	}

	return (<div className={'exitintent__vip' + (isAbsolute ? ' exitintent__vip--absolute' : '')} ref={modalRef}>
		<div className="exitintent__vip-headline">{t('headline')}</div>
		<div className="exitintent__vip-text" dangerouslySetInnerHTML={{__html: t('text')}}></div>
		<VXButton color={COLOR_TYPE_GOLD}
		          line1={[new VXButtonLineConfig(t('button'))]}
		          onClick={() => setAccept(true)}
		          size={STYLES.SIZE.SMALL}
		/>
		<div className="exitintent__vip-logout" onClick={() => setAccept(false)}>{t('logout')}</div>
	</div>);
};

VipLogout.propTypes = {
	isAbsolute:    PropTypes.bool,
	onLogoutClick: PropTypes.func,
	incentive:     PropTypes.object,
};

VipLogout.defaultProps = {
	isAbsolute: false,
};

export default VipLogout;
